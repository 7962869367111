<template>
  <figure
    component-name="card-variant"
    class="flex flex-col gap-md text-skin-base isolate"
  >
    <div
      class="relative top-0 left-0 w-full overflow-hidden aspect-1/1"
      :class="{
        'has-[.router-link-exact-active]:!pointer-events-none':
          useLinkExactActive,
      }"
    >
      <!-- WRAPPER ACTIONS -->
      <wrapper-actions
        :asset="minimalMedia"
        :use-favourite="slug ? { slug } : undefined"
        :use-moodboard="button.action?.to ? { slug } : undefined"
        :use-download="button.action?.to ? true : false"
        :use-configurator="useConfigurator"
        :use-remove="useRemove ? { slug } : undefined"
        :force-hover="forceHover"
        class="absolute inset-0 w-full h-full"
      >
        <btn
          :title="button.title"
          :use-router-link-class="true"
          :action="button.action?.to ? button.action : undefined"
          :pressed="!button.action?.to || undefined"
          class="relative top-0 left-0 w-full h-full overflow-hidden"
          :class="{
            '[&.router-link-exact-active]:pointer-events-none [&.router-link-exact-active_[data-link-exact-active]]:!visible':
              useLinkExactActive,
          }"
        >
          <wrapper-reveal
            reveal="fade-from-bottom"
            intensity="strong"
            class="w-full h-full"
          >
            <div class="relative top-0 left-0 w-full h-full overflow-hidden">
              <!-- AMBIENT MEDIA -->
              <media
                v-bind="{
                  ...ambientMedia,
                  height: ambientMedia.width,
                  aspectRatio: '1 / 1',
                }"
                class="will-change-transform w-full h-full brightness-50 scale-110 transition-[transform,filter] group-hover/btn:scale-100 group-hover/btn:brightness-100 group-hover/btn:duration-[1400ms] duration-500 group-hover/btn:ease-circ-out ease-cubic-in-out"
                :class="{
                  '!scale-100 !brightness-100 !duration-[1400ms] !ease-circ-out':
                    forceHover,
                }"
              />
              <!-- end AMBIENT MEDIA -->

              <!-- MINIMAL MEDIA -->
              <media
                v-bind="{
                  ...minimalMedia,
                  height: minimalMedia.width,
                  aspectRatio: '1 / 1',
                }"
                :use-bg="true"
                class="absolute -top-px -left-px w-[calc(100%+2px)] h-[calc(100%+2px)] ease-in-out translate-y-0 will-change-transform transition-fade duration-400 group-hover/btn:duration-500 group-hover/btn:opacity-0"
                :class="{
                  '!duration-500 !opacity-0': forceHover,
                }"
              />
              <!-- end MINIMAL MEDIA -->
            </div>
          </wrapper-reveal>

          <!-- RESOURCE SVG -->
          <wrapper-reveal
            v-if="resource"
            class="absolute z-2 inset-0 w-full h-full p-md flex justify-end items-start"
            reveal="fade-from-bottom"
            intensity="soft"
            :delay="150"
          >
            <svg-resource-badge />
          </wrapper-reveal>
          <!-- end RESOURCE SVG -->

          <transition-fade v-if="useLinkExactActive" duration="slow">
            <div class="invisible absolute inset-0 z-3 w-full h-full">
              <wrapper-reveal
                reveal="fade-from-bottom"
                intensity="strong"
                class="w-full h-full"
              >
                <span
                  v-if="useLinkExactActive"
                  data-link-exact-active
                  class="w-full h-full flex justify-center items-center border-[0.5rem] border-current"
                >
                  <span class="p-md bg-skin-inverse text-skin-inverse"
                    ><svg-icon icon="success" size="huge"
                  /></span>
                </span>
              </wrapper-reveal>
            </div>
          </transition-fade>
        </btn>
      </wrapper-actions>
      <!-- end WRAPPER ACTIONS -->
    </div>

    <!-- CAPTION -->
    <figcaption class="flex flex-col w-full gap-xs">
      <wrapper-reveal reveal="fade-from-right">
        <btn
          v-if="button.action?.to"
          :title="button.title"
          :action="button.action"
          @mouseenter="forceHover = $device.isDesktop"
          @mouseleave="forceHover = false"
          class="block"
          :class="{
            '[&.router-link-exact-active]:pointer-events-none':
              useLinkExactActive,
          }"
        >
          <div v-html="button.title" class="!text-body/tight"></div>
        </btn>
        <div v-else v-html="button.title" class="text-body/tight"></div>
      </wrapper-reveal>

      <!-- CHIPS -->
      <wrapper-reveal
        v-if="atelier"
        reveal="fade-from-bottom"
        intensity="strong"
      >
        <div class="flex flex-col gap-xs">
          <chip-label variant="text-small" target="atelier" />
          <div class="inline-flex text-overline-small/tight text-skin-muted">
            <span>{{ $t.labels.designer }}</span>
            <span>&nbsp;</span>
            <span>{{ atelier }}</span>
          </div>
        </div>
      </wrapper-reveal>

      <wrapper-reveal
        v-if="resource"
        reveal="fade-from-bottom"
        intensity="strong"
      >
        <div class="flex flex-col gap-xs">
          <chip-label variant="text-small" target="resource" />
          <div class="inline-flex text-overline-small/tight text-skin-muted">
            {{ $t.texts.resource_short_desc }}
          </div>
        </div>
      </wrapper-reveal>
      <!-- end CHIPS -->

      <wrapper-reveal v-if="indoor || outdoor" reveal="fade-from-bottom">
        <txt-indoor-outdoor :indoor="indoor" :outdoor="outdoor" />
      </wrapper-reveal>

      <wrapper-reveal v-if="!button.action?.to" reveal="fade-from-0">
        <div
          v-html="$t.labels.not_available"
          class="text-body-small/none text-skin-danger"
        ></div>
      </wrapper-reveal>
    </figcaption>
    <!-- end CAPTION -->
  </figure>
</template>

<script setup>
const props = defineProps({
  minimalMedia: {
    type: Object,
    required: true,
  },
  ambientMedia: {
    type: Object,
    required: true,
  },
  button: {
    type: Object,
    required: true,
  },
  indoor: {
    type: Boolean,
    required: false,
    default: false,
  },
  outdoor: {
    type: Boolean,
    required: false,
    default: false,
  },
  useRemove: {
    type: Boolean,
    required: false,
    default: false,
  },
  useConfigurator: {
    type: Object,
    required: false,
  },
  slug: {
    type: String,
    required: true,
  },
  atelier: {
    type: String,
    required: false,
  },
  resource: {
    type: Boolean,
    required: false,
  },
  useLinkExactActive: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const forceHover = ref(false);
</script>
